import { useState, FC, useEffect, useMemo } from "react";
import classNames from "classnames";
import { useAppSelector } from "../store/hooks";
import {
  BusinessType,
  BusinessUnitInterface,
  LocationInterface,
} from "../interfaces";
import { FormSearch } from "./FormFields";
import {
  AutocompleteRegion,
  FormMapsAutocomplete,
} from "./Form/FormMapsAutocomplete";

const BusinessUnitOption = ({ location, code }: BusinessUnitInterface) => {
  return (
    <div className="flex items-center">
      <div className="flex flex-col">
        <p className="text-sm font-semibold leading-5 truncate">
          {!!code ? `${code} - ` : ""}
          {location.name}
        </p>
        <p className="text-sm leading-5">
          {location?.address}{" "}
          {!!location?.postalCode
            ? `(código postal: ${location?.postalCode})`
            : ""}
        </p>
      </div>
    </div>
  );
};

interface BusinessUnitSearchProps {
  disabled?: boolean;
  selected?: BusinessUnitInterface;
  error?: string;
  buSource?: BusinessUnitInterface;
  setSelectedLocation: (businessUnit: BusinessUnitInterface) => void;
}
export const BusinessUnitSearch: FC<BusinessUnitSearchProps> = ({
  disabled,
  selected,
  error,
  buSource,
  setSelectedLocation,
}) => {
  const [search, setSearch] = useState("");
  const user = useAppSelector((state) => state.user);
  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );

  const businessUnitOptions = useMemo(() => {
    const buSourceId = buSource?.id ?? user.businessUnit?.id;

    const business = businessUnits.filter(
      (businessUnit) =>
        businessUnit.id !== buSourceId &&
        //businessUnit.code !== '8110' &&
        businessUnit.code !== "KK" &&
        businessUnit.code !== "NN" &&
        //businessUnit.code !== 'MAD' &&
        //businessUnit.code !== 'MIA' &&
        //businessUnit.code !== 'PTY' &&
        !businessUnit.isLocked &&
        (businessUnit.type === BusinessType.EXTERNAL ||
          businessUnit.type === BusinessType.INTERNAL)
    );
    // Delete duplicates by id
    const uniqueIds = new Set();
    const result: BusinessUnitInterface[] = [];
    business.forEach((businessUnit, index) => {
      if (!uniqueIds.has(businessUnit.id)) {
        uniqueIds.add(businessUnit.id);
        result.push(businessUnit);
      }
    });

    result.sort((a, b) => {
      const tokens = search.split(" ");

      const aMatch = tokens.some((token) => token === a.code);
      const bMatch = tokens.some((token) => token === b.code);

      if (aMatch && !bMatch) return -1;
      if (!aMatch && bMatch) return 1;
      return 0;
    });

    return result;
  }, [businessUnits, user.businessUnit, search, buSource?.id]);

  const filter = (option: BusinessUnitInterface, search: string) => {
    const tokens = search
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .split(" ");

    return tokens.every((token) => {
      return (
        token === "-" ||
        option.location.name.toLowerCase().includes(token) ||
        option.code?.toLowerCase().includes(token) ||
        option.location.address.toLowerCase().includes(token) ||
        !!option.location.postalCode?.toLowerCase().includes(token) ||
        !!option.location.reference?.toLowerCase().includes(token)
      );
    });
  };

  useEffect(() => {
    setSearch(!!selected ? `${selected.code} - ${selected.location.name}` : "");
  }, [selected]);

  return (
    <div className="relative w-full" id={`input-business-unit`}>
      <FormSearch
        id="input-business-unit"
        value={search}
        options={businessUnitOptions}
        error={error}
        disabled={disabled}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Buscar tienda..."
        filter={filter}
        onSelectOption={(option) => {
          setSelectedLocation(option);
          setSearch(`${option.code} - ${option.location.name}`);
        }}
        onChangeFocus={(focus) => {
          if (!focus && selected)
            setSearch(`${selected.code} - ${selected.location.name}`);
        }}
        RenderOption={({ option }) => <BusinessUnitOption {...option} />}
      />
    </div>
  );
};

interface AddressSearchProps {
  /**
   * Stores with the territories that TEALCA covers
   */
  businessUnits: BusinessUnitInterface[];
  /**
   * Location selected by the user
   */
  selected?: LocationInterface;
  /**
   * Indicates if the input is disabled
   */
  disabled?: boolean;
  /**
   * If true, the user can only select locations that are inside the stores
   */
  region: AutocompleteRegion;
  /**
   * If true, international shipment is selected
   */
  isInternational?: Boolean;
  /**
   * Error message to display
   */
  error?: string;
  /**
   * Callback function that is called when the user selects a location
   */
  setSelectedLocation: (
    location: LocationInterface,
    businessUnit?: BusinessUnitInterface
  ) => void;
  /**
   * Callback function that is called when the user selects a country
   */
  setShortCountryName?: (shortCountryName: string) => void;
}
export const AddressSearch: FC<AddressSearchProps> = ({
  businessUnits,
  selected,
  region,
  disabled,
  isInternational,
  error,
  setSelectedLocation,
  setShortCountryName,
}) => {
  return (
    <div className={isInternational ? "relative w-2/5" : "relative w-full"}>
      {!!businessUnits && (
        <FormMapsAutocomplete
          id="location-autocomplete"
          name="location-autocomplete"
          error={error}
          region={region}
          selected={selected}
          disabled={disabled}
          isInternational={!!isInternational}
          onSelectLocation={setSelectedLocation}
          setShortCountryName={setShortCountryName}
          placeholder={isInternational ? "Buscar país..." : "Buscar ubicación"}
        />
      )}
    </div>
  );
};
