import { PaymentInterface } from "./PaymentInterface";
import { AccountInterface } from "./AccountInterface";
import {
  PaymentMode,
  ShipmentInterface,
  ShipmentSimpleInterface,
} from "./ShipmentInterface";
import { Nullable } from "./GenericTypes";
import { string } from "yup";

export type TaxType = "percentage" | "fixed";

export interface TaxInterface {
  name: string;
  value: number;
  type: TaxType;
}

export enum DocumentStatus {
  ANULLED = 6,
  PAID = 10,
  PENDING = 11,
  PARTIAL_PAID = 20,
  TO_ISSUE = 59,
  ISSUED = 60,
}

export enum DocumentType {
  INVOICE = 2,
  CREDIT_NOTE = 4,
  ORDER = 6,
}

export interface DocumentInterface {
  documentID: string;
  documentNumber?: Nullable<string>;
  documentType?: DocumentType;
  documentTypeCode?: string;
  documentTypeName?: string;
  documentAffectedID?: string;
  documentAffectedDocumentNumber?: string;
  documentAppliedID?: string;
  documentAppliedDocumentNumber?: string;
  accountOwner?: AccountInterface;
  paymentMode: PaymentMode;
  subTotal: number;
  total: number;
  status: DocumentStatus;
  shipments: ShipmentInterface[] | ShipmentSimpleInterface[];
  payments: PaymentInterface[];
  taxes: TaxInterface[];
  printed: boolean;
  creationDate?: Date;
  updateDate?: Date;
  hasRetention: boolean;
  balanceAmount: number;
  baseBalanceAmount?: number;
  ipostel?: number;
  igtfAmount: number;
  urlDocument?: string;
  buCodeInvoicer?: string;
  fiscalControlNumber?: string;
  isInternational?: boolean;
}

export interface AccountSimpleInterface {
  id: string;
}

export interface StoreDocumentInterface {
  documentID: string;
  documentGroup: string;
  documentNumber: string;
  creationDate: string;
  documentDate: string;
  accountName: string;
  accountFiscalNumber: string;
  totalAmountBaseCurr: number;
  balanceAmountBaseCurr: number;
  countShipmentDocument: number;
  documentAffectedID?: string;
  documentAffectedNumber?: string;
  documentAppliedID?: string;
  documentAppliedNumber?: string;
  documentTypeID: DocumentType;
  paymentModeID: PaymentMode;
  statusID: DocumentStatus;
  totalDocuments?: number;
}

export interface DocumentItemInterface {
  ItemID: string;
  ItemSeqNumber: number;
  Quantity: number;
  ItemName: string;
  GrossAmount: number;
  GrossAmountBaseCurr: number;
  DiscountAmount: number;
  DiscountAmountBaseCurr: number;
  PctTax: number;
  TaxAmount: number;
  TaxAmountBaseCurr: number;
  TotalAmount: number;
  TotalAmountBaseCurr: number;
  CreationUser?: Nullable<string>;
  CreationDate: Date;
  UpdateUser?: Nullable<string>;
  StatusID: number;
}
