import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Undefinable,
  PaymentMode,
  TaxInterface,
  DeliveryType,
  ShipmentService,
  AccountInterface,
  PaymentInterface,
  ShipmentInterface,
  DocumentInterface,
  ShipmentStatus,
  LocationInterface,
  ItemInterface,
} from "../../interfaces";
import { CouponDTO } from "../../interfaces/Dtos/CouponDTO";
import { AddressValidationResponseItem } from "../../interfaces/DHLInterface";

export enum ShipmentCreatePhase {
  SHIPMENTS,
  DOCUMENT,
  PAYMENT,
  RESUME,
}

interface ShipmentCreateState {
  coupon?: CouponDTO;
  taxes: TaxInterface[];
  owner?: AccountInterface;
  phase: ShipmentCreatePhase;
  document?: DocumentInterface;
  payments: PaymentInterface[];
  shipments: ShipmentInterface[];
  shipmentDrafts: Partial<ShipmentInterface>[];
  deliveryType: DeliveryType;
  cityValue: string;
  cityName: string;
  internationalAddress?: LocationInterface;
  countryCode: string;
  optionSelected: boolean;
  DHLItem: ItemInterface;
  destination: AddressValidationResponseItem;
  serviceType: ShipmentService;
}

interface ShipmentCreateInterfaceAction {
  index: number;
  shipment: Partial<ShipmentInterface>;
}

const initialState: ShipmentCreateState = {
  shipments: [],
  shipmentDrafts: [
    {
      pieces: [],
      items: [],
      deliveryType: DeliveryType.AT_OFFICE,
      paymentMode: PaymentMode.COD,
      service: ShipmentService.STANDARD,
      status: ShipmentStatus.DRAFT,
    },
  ],
  phase: ShipmentCreatePhase.SHIPMENTS,
  payments: [],
  taxes: [],
  deliveryType: DeliveryType.AT_OFFICE,
  cityValue: "",
  cityName: "",
  internationalAddress: {
    name: "",
    code: "",
    address: "",
    reference: "",
    postalCode: "",
    coordinates: {
      lat: 0,
      lng: 0,
    },
  },
  countryCode: "",
  optionSelected: false,
  DHLItem: {
    id: "",
    code: "",
    name: "",
    order: 0,
    mandatory: true,
    isItemBase: false,
    rate: {
      value: 0,
      iva: 0,
      ipostel: 0,
      isPercentage: false,
      distance: 0,
      tierCode: "",
      tierName: "",
    },
  },
  destination: {
    countryCode: "",
    postalCode: "",
    cityName: "",
    countyName: "",
    serviceArea: {
      code: "",
      description: "",
      GMTOffset: "",
    },
  },
  serviceType: ShipmentService.STANDARD,
};

export const shipmentCreateSlice = createSlice({
  name: "pickup",
  initialState,
  reducers: {
    setShipmentCreateList: (
      state,
      action: PayloadAction<ShipmentInterface[]>
    ) => {
      state.shipments = action.payload;
    },
    setShipmentCreateDrafts: (
      state,
      action: PayloadAction<Partial<ShipmentInterface>[]>
    ) => {
      state.shipmentDrafts = action.payload;
    },
    setShipmentCreateDraft(
      state,
      action: PayloadAction<ShipmentCreateInterfaceAction>
    ) {
      const { index, shipment } = action.payload;
      const currentDraft = state.shipmentDrafts[index];
      state.shipmentDrafts[index] = { ...currentDraft, ...shipment };
    },
    setShipmentCreatePhase: (
      state,
      action: PayloadAction<ShipmentCreatePhase>
    ) => {
      state.phase = action.payload;
    },
    setShipmentCreateOwner: (
      state,
      action: PayloadAction<Undefinable<AccountInterface>>
    ) => {
      state.owner = action.payload;
    },
    setShipmentCreatePayments: (
      state,
      action: PayloadAction<PaymentInterface[]>
    ) => {
      state.payments = action.payload;
    },
    setShipmentCreateTaxes: (state, action: PayloadAction<TaxInterface[]>) => {
      state.taxes = action.payload;
    },
    setShipmentCreateDocument: (
      state,
      action: PayloadAction<DocumentInterface>
    ) => {
      state.document = action.payload;

      // Set the other data
      state.owner = action.payload.accountOwner;
      state.taxes = action.payload.taxes;
      state.payments = action.payload.payments;
      state.shipments = action.payload.shipments as ShipmentInterface[];
    },
    setShipmentCreateCoupon: (
      state,
      action: PayloadAction<CouponDTO | undefined>
    ) => {
      state.coupon = action.payload;
    },
    setShipmentDeliveryType: (state, action: PayloadAction<DeliveryType>) => {
      state.deliveryType = action.payload;
    },
    setCityValue: (state, action: PayloadAction<string>) => {
      state.cityValue = action.payload;
    },
    setCityName: (state, action: PayloadAction<string>) => {
      state.cityName = action.payload;
    },
    setInternationalAddress: (
      state,
      action: PayloadAction<LocationInterface | undefined>
    ) => {
      state.internationalAddress = action.payload;
    },
    setCountryCode: (state, action: PayloadAction<string>) => {
      state.countryCode = action.payload;
    },
    setOptionSelected: (state, action: PayloadAction<boolean>) => {
      state.optionSelected = action.payload;
    },
    setDHLItem: (state, action: PayloadAction<ItemInterface>) => {
      state.DHLItem = action.payload;
    },
    setShipmentDestination: (
      state,
      action: PayloadAction<AddressValidationResponseItem>
    ) => {
      state.destination = action.payload;
    },
    setShipmentServiceType: (state, action: PayloadAction<ShipmentService>) => {
      state.serviceType = action.payload;
    },
    clearShipmentCreate: (state) => {
      state.payments = [];
      state.shipments = [];
      state.taxes = [];
      state.document = undefined;
      state.owner = undefined;
      state.coupon = undefined;
      state.phase = ShipmentCreatePhase.SHIPMENTS;
      state.shipmentDrafts = [
        {
          pieces: [],
          items: [],
          service: ShipmentService.STANDARD,
          deliveryType: DeliveryType.AT_OFFICE,
          paymentMode: PaymentMode.COD,
          status: ShipmentStatus.DRAFT,
        },
      ];
      state.deliveryType = DeliveryType.AT_OFFICE;
      state.cityValue = "";
      state.cityName = "";
      state.serviceType = ShipmentService.STANDARD;
      state.internationalAddress = {
        name: "",
        code: "",
        address: "",
        reference: "",
        postalCode: "",
        coordinates: {
          lat: 0,
          lng: 0,
        },
      };
      state.countryCode = "";
      state.optionSelected = false;
      state.DHLItem = {
        id: "",
        code: "",
        name: "",
        order: 0,
        mandatory: true,
        isItemBase: false,
        rate: {
          value: 0,
          iva: 0,
          ipostel: 0,
          isPercentage: false,
          distance: 0,
          tierCode: "",
          tierName: "",
        },
      };
      state.destination = {
        countryCode: "",
        postalCode: "",
        cityName: "",
        countyName: "",
        serviceArea: {
          code: "",
          description: "",
          GMTOffset: "",
        },
      };
    },
  },
});

export const {
  clearShipmentCreate,
  setShipmentCreateList,
  setShipmentCreateDraft,
  setShipmentCreateTaxes,
  setShipmentCreateCoupon,
  setShipmentCreateDocument,
  setShipmentCreatePhase,
  setShipmentCreateOwner,
  setShipmentCreateDrafts,
  setShipmentCreatePayments,
  setShipmentDeliveryType,
  setCityValue,
  setCityName,
  setInternationalAddress,
  setCountryCode,
  setOptionSelected,
  setDHLItem,
  setShipmentDestination,
  setShipmentServiceType,
} = shipmentCreateSlice.actions;

export default shipmentCreateSlice.reducer;
