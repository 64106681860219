import React, { useState, useCallback, useMemo, FC, useEffect } from "react";
import Modal from "../Modal";
import { debounce } from "lodash";
import { PrimaryButton } from "../Buttons";
import { formatName } from "../../utils/format";
import { useAppSelector } from "../../store/hooks";
import { FormSearch, FormGroupSearch } from "../FormFields";
import {
  UserPlusIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import {
  getAccount,
  loaderService,
  getFilteredAccounts,
  getFilteredBoxAccounts,
  getFavoriteAndHistoricalLocations,
} from "../../services";
import {
  AccountInterface,
  LocationInterface,
  BoxAccountInterface,
  DestinationInterface,
  BusinessUnitInterface,
  RecurrentDestinationInterface,
  DeliveryType,
} from "../../interfaces";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

const ClientOption: FC<AccountInterface> = ({
  accountFullName,
  listAccountPhone,
  abreviationName,
  identificationNumber,
  agreementID,
  listAuthorizingAccount,
}: AccountInterface) => {
  const isEnterprise = !!agreementID || listAuthorizingAccount?.length > 0;

  return (
    <div className="flex items-center">
      <div className="flex flex-col">
        <div className="flex items-center text-sm font-semibold leading-5 truncate">
          {formatName(accountFullName)}

          <CheckBadgeIcon
            title="Cliente Crédito Corporativo"
            data-te-toggle="tooltip"
            className={classNames(
              "ml-2 inline h-5 w-5 text-indigo-600 group-hover:text-white",
              !isEnterprise && "hidden"
            )}
          />
        </div>

        {!listAccountPhone ||
        listAccountPhone.length === 0 ||
        (abreviationName !== "V-" && abreviationName !== "E-") ? (
          <p className="text-sm leading-5">
            {abreviationName}
            {identificationNumber}
          </p>
        ) : (
          <p className="text-sm leading-5">
            {!!listAccountPhone &&
              listAccountPhone.length > 0 &&
              listAccountPhone[0].phoneNumber}
          </p>
        )}
      </div>
    </div>
  );
};

const LocationOption: FC<RecurrentDestinationInterface> = ({
  deliveryTypeID,
  consigneeFullName,
  consigneeIdentificationType,
  consigneeIdentificationNumber,
  consigneePhone,
  buCode,
  buAreaName,
  addressName,
  addressLine1,
  addressLandMark,
  postalCode,
}) => {
  const taxIdentificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const abreviationName = useMemo(() => {
    return taxIdentificationTypes.find(
      (type) => type.taxIdentificationTypeId === consigneeIdentificationType
    )?.abreviationName;
  }, [consigneeIdentificationType, taxIdentificationTypes]);

  return (
    <div className="flex items-center">
      <div className="flex flex-col w-full">
        <p className="text-sm font-semibold leading-5 truncate">
          {formatName(consigneeFullName)}
        </p>
        <p className="text-sm leading-5">
          {abreviationName}
          {consigneeIdentificationNumber}
        </p>
        <p className="text-sm leading-5">{consigneePhone}</p>

        {deliveryTypeID === DeliveryType.AT_OFFICE && (
          <p className="text-sm leading-5">
            {buCode} - {buAreaName}
          </p>
        )}

        {deliveryTypeID === DeliveryType.AT_HOME && (
          <p className="leading-5 truncate">
            {addressName}
            <br />
            <span className="text-xs">
              {addressLine1} {postalCode && `(código postal: ${postalCode})`}
            </span>
            <br />
            <span className="text-xs">
              {addressLandMark}
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

interface BoxClientOptionProps {
  boxAccount: BoxAccountInterface;
}
const BoxClientOption: FC<BoxClientOptionProps> = ({ boxAccount }) => {
  return (
    <div className="flex items-center">
      <div className="flex flex-col">
        <p className="text-sm font-semibold leading-5 truncate">
          Casillero {boxAccount.boxAccountCode}
        </p>
        <p className="text-sm font-semibold leading-5 truncate">
          {boxAccount.accountFullName}
        </p>
        <p className="text-sm leading-5">
          {boxAccount.accountAbreviationName}
          {boxAccount.accountIdentificationNumber}
        </p>
      </div>
    </div>
  );
};

const AddClient: FC<{ onClick: React.MouseEventHandler<HTMLDivElement> }> = ({
  onClick,
}) => {
  return (
    <div className="flex flex-1 py-3 items-center opacity-75" onClick={onClick}>
      <UserPlusIcon className="h-5 w-5 mr-2 shrink-0" />

      <p className="text-sm truncate">Crear nuevo</p>
    </div>
  );
};
// Filter client-destination by search
export const filterDestination = (
  search: string,
  client?: AccountInterface,
  location?: LocationInterface
) => {
  const criteria = search.toLowerCase();
  // split by tokens separated by spaces
  const tokens = criteria
    .split(" ")
    .filter((token) => token !== "")
    .map((token) => token.toLowerCase());
  if (tokens.length === 0) {
    return true;
  }
  // filter by tokens
  return tokens.every((token) =>
    filterByTokenDestination(token, client, location)
  );
};
// Filter client-destination by token
export const filterByTokenDestination = (
  token: string,
  client?: AccountInterface,
  location?: LocationInterface
) => {
  return (
    location?.name.toLowerCase().includes(token) ||
    location?.address.toLowerCase().includes(token) ||
    client?.accountFullName.toLowerCase().includes(token) ||
    ((client?.abreviationName ?? "") + client?.identificationNumber)
      .toLowerCase()
      .includes(token) ||
    (!!client?.listAccountPhone &&
      client?.listAccountPhone.some((phone) =>
        phone.phoneNumber.toLowerCase().includes(token)
      ))
  );
};

interface AccountIdentification {
  abreviationName: string;
  identificationNumber: string;
}
interface AccountSearchProps {
  title: string;
  selected?: AccountInterface;
  disabled?: boolean;
  error?: string;
  placeholder?: string;
  hideCreate?: boolean;
  ignoredAccounts?: AccountIdentification[];
  onChange?: (search: string) => void;
  onSelectClient: (option: AccountInterface) => void;
  openCreationModal: (open: boolean) => void;
}
export const AccountSearch: FC<AccountSearchProps> = ({
  title,
  selected,
  disabled = false,
  error,
  placeholder,
  hideCreate = false,
  ignoredAccounts = [],
  onChange = () => {},
  onSelectClient,
  openCreationModal,
}) => {
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [currentError, setCurrentError] = useState(error);
  const [clients, setClients] = useState<AccountInterface[]>([]);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

  const getClientsData = async (criteria: string) => {
    if (criteria === "") {
      setClients([]);
      return;
    }

    setLoader(true);
    const clients = await getFilteredAccounts(criteria);
    if (!clients.didError && !!clients.model) {
      setClients(
        clients.model.filter((client) => {
          const account = ignoredAccounts.find(
            (ignored) =>
              ignored.abreviationName === client.abreviationName &&
              ignored.identificationNumber === client.identificationNumber
          );
          return !account;
        })
      );
    } else {
      setCurrentError(clients.errorMessage);
    }
    setLoader(false);
  };

  // Handle search by debouncing the event
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setCurrentError("");
    setSearch(inputValue);
  };

  useEffect(() => {
    setSearch(formatName(selected?.accountFullName ?? ""));
  }, [selected]);

  useEffect(() => {
    onChange(search);
  }, [search, onChange]);

  useEffect(() => {
    setCurrentError(error);
  }, [error]);

  useEffect(() => {
    if (timerId) {
      clearTimeout(timerId);
    }
    if (search.length > 3) {
      const newTimerId = setTimeout(() => {
        getClientsData(search);
      }, 1000);

      setTimerId(newTimerId);
    } else {
      getClientsData("");
    }
  }, [search]);

  return (
    <div className="flex flex-1 flex-col">
      <div className="relative w-full">
        <FormSearch
          value={search}
          id={`input${title}`}
          label={title}
          loader={loader}
          options={clients}
          disabled={disabled}
          error={currentError}
          placeholder={placeholder}
          onChange={handleChange}
          onSelectOption={async (option) => {
            onSelectClient(option);
            setSearch(formatName(option?.accountFullName ?? ""));
          }}
          onChangeFocus={(focus) => {
            if (!focus && selected)
              setSearch(formatName(selected.accountFullName));
          }}
          RenderOption={({ option }) => <ClientOption {...option} />}
          LastOption={
            hideCreate
              ? undefined
              : () => <AddClient onClick={() => openCreationModal(true)} />
          }
        />
      </div>
    </div>
  );
};

interface BoxAccountSearchProps {
  title?: string;
  selected?: BoxAccountInterface;
  shipperAccount?: AccountInterface;
  error?: string;
  onChange?: (search: string) => void;
  setBoxAccount: (
    boxAccount: BoxAccountInterface,
    client: AccountInterface,
    businessUnit: BusinessUnitInterface
  ) => void;
}
export const BoxAccountSearch: FC<BoxAccountSearchProps> = ({
  title,
  selected,
  shipperAccount,
  error,
  onChange = () => {},
  setBoxAccount,
}) => {
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [currentError, setCurrentError] = useState(error);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );
  const [boxAccounts, setBoxAccounts] = useState<BoxAccountInterface[]>([]);
  const [notFound, setNotFound] = useState("Código de casillero inválido");

  const getBoxAccountsData = useCallback(
    async (boxCode: string) => {
      if (boxCode.length < 2) {
        setBoxAccounts([]);
        return;
      }

      setLoader(true);
      const boxes = await getFilteredBoxAccounts(boxCode, businessUnits);
      if (!boxes.didError && boxes.model) {
        setBoxAccounts(boxes.model);
      } else {
        setCurrentError(boxes.errorMessage);
      }
      setLoader(false);
    },
    [businessUnits]
  );

  const debouncedSearch = useMemo(
    () => debounce(getBoxAccountsData, 500),
    [getBoxAccountsData]
  );

  const searchCallback = useCallback(
    (boxCode: string) => debouncedSearch(boxCode),
    [debouncedSearch]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setCurrentError("");
    setSearch(inputValue);
    searchCallback(inputValue);
  };

  const handleSelect = async (boxAccount: BoxAccountInterface) => {
    if (
      !!boxAccount.authorizedShippers &&
      boxAccount.authorizedShippers.length > 0
    ) {
      const foundShipper = boxAccount.authorizedShippers.find(
        (account) => account.accountId === shipperAccount?.id
      );
      if (!foundShipper) {
        setBoxAccounts([]);
        setNotFound(
          "El cliente no está autorizado a enviar al casillero " +
            boxAccount.boxAccountCode
        );
        setOpenErrorModal(true);
        return;
      }
    }

    loaderService.start();
    const account = await getAccount(boxAccount.accountID);
    loaderService.stop();
    if (!account) {
      return;
    }

    setBoxAccount(boxAccount, account, boxAccount.businessUnit);
  };

  useEffect(() => {
    setSearch(selected?.boxAccountCode ?? "");
  }, [selected]);

  useEffect(() => {
    onChange(search);
  }, [search, onChange]);

  useEffect(() => {
    setCurrentError(error);
  }, [error]);

  return (
    <div className="flex flex-1">
      <div className="relative w-full" id={`input-casillero`}>
        <FormGroupSearch
          value={search}
          label={title}
          error={currentError}
          optionGroups={[
            {
              loader,
              title: "Casilleros",
              options: boxAccounts,
            },
          ]}
          notFound={notFound}
          disabled={!shipperAccount}
          onChange={handleChange}
          placeholder="Buscar casillero..."
          onSelectOption={(option) => handleSelect(option)}
          onChangeFocus={(focus) => {
            if (!focus && selected) setSearch(selected.boxAccountCode);
          }}
          RenderOption={({ option }) => <BoxClientOption boxAccount={option} />}
        />
      </div>

      <Modal openModal={openErrorModal} setOpenModal={setOpenErrorModal}>
        <div
          className="flex flex-col items-center justify-center"
          style={{ maxWidth: "25rem" }}
        >
          <div className="flex flex-col items-center justify-center w-full">
            <ExclamationTriangleIcon className="w-32 h-32" />
          </div>
          <p className="mt-2 text-xl text-center text-gray-900">{notFound}</p>
          <div className="mt-4 flex flex-row justify-center gap-4">
            <PrimaryButton
              className="px-4"
              onClick={() => setOpenErrorModal(false)}
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

interface AccountSearchFavoriteHistoricProps extends AccountSearchProps {
  shipperId?: string;
  autoCompleteOff?: boolean;
  onSelectDestination: (destination: RecurrentDestinationInterface) => void;
}
export const AccountSearchFavoriteHistoric: FC<
  AccountSearchFavoriteHistoricProps
> = ({
  title,
  error,
  selected,
  disabled,
  shipperId,
  placeholder,
  autoCompleteOff,
  onSelectClient,
  onSelectDestination,
  openCreationModal,
}) => {
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [currentError, setCurrentError] = useState(error);
  const [clients, setClients] = useState<AccountInterface[]>([]);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
  const [searchingFavAndHist, setSearchingFavAndHist] = useState(false);
  const [favoriteList, setFavoriteList] = useState<
    RecurrentDestinationInterface[]
  >([]);
  const [historicList, setHistoricList] = useState<
    RecurrentDestinationInterface[]
  >([]);

  const taxIdTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const getClientsData = async (criteria: string) => {
    setLoader(true);

    if (criteria === "") {
      setClients([]);
      return;
    }

    const clients = await getFilteredAccounts(criteria);
    if (!clients.didError && !!clients.model) {
      setClients(clients.model);
    } else {
      setClients([]);
      setCurrentError(clients.errorMessage);
    }
    setLoader(false);
  };

  // Handle search by debouncing the event
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setCurrentError("");
    setSearch(inputValue);
  };

  const getLocations = async (canceled?: boolean) => {
    if (!shipperId) {
      setFavoriteList([]);
      setHistoricList([]);
      return;
    }

    setSearchingFavAndHist(true);
    const response = await getFavoriteAndHistoricalLocations(shipperId, search);
    setSearchingFavAndHist(false);

    if (canceled || response.didError || !response.model) {
      setFavoriteList([]);
      setHistoricList([]);
      return;
    }

    setFavoriteList(response.model.filter((fav) => fav.tipoAdr === "Favorito"));
    setHistoricList(response.model.filter((fav) => fav.tipoAdr === "Sales"));
  };

  useEffect(() => {
    setSearch(formatName(selected?.accountFullName ?? ""));
  }, [selected]);

  useEffect(() => {
    setCurrentError(error);
  }, [error]);

  useEffect(() => {
    if (timerId) {
      clearTimeout(timerId);
    }

    const newTimerId = setTimeout(() => {
      getClientsData(search);
      getLocations();
    }, 1000);

    setTimerId(newTimerId);
  }, [search]);

  // Get favorite and historical locations from selected shipper
  useEffect(() => {
    let canceled = false;

    getLocations(canceled);

    return () => {
      canceled = true;
    };
  }, [shipperId, taxIdTypes]);

  return (
    <div className="flex flex-1 flex-col">
      <div className="relative w-full">
        <FormGroupSearch<RecurrentDestinationInterface | AccountInterface>
          value={search}
          id={`search-favorite-historic-account-${title}`}
          label={title}
          disabled={disabled}
          error={currentError}
          placeholder={placeholder}
          optionGroups={[
            {
              loader: searchingFavAndHist,
              title: "Favoritos",
              options: favoriteList.slice(0, 5),
            },
            {
              loader: searchingFavAndHist,
              title: "Recientes",
              options: historicList.slice(0, 5),
            },
            {
              loader,
              title: "Clientes",
              options: clients,
            },
          ]}
          onChange={handleChange}
          onSelectOption={(option) => {
            if (!option) return;

            if ("tipoAdr" in option) {
              setSearch(option.consigneeFullName);
              onSelectDestination(option);
            } else {
              setSearch(option.accountFullName);
              onSelectClient(option);
            }
          }}
          onChangeFocus={(focus) => {
            if (!focus) setSearch(formatName(selected?.accountFullName ?? ""));
          }}
          RenderOption={({ option }) => {
            if ("tipoAdr" in option) {
              return (
                <LocationOption {...option} />
              );
            } else {
              return <ClientOption {...option} />;
            }
          }}
          LastOption={() => (
            <AddClient onClick={() => openCreationModal(true)} />
          )}
          autoComplete="off"
        />
      </div>
    </div>
  );
};
